import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Col, Container, Row, Media } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import ModalVideo from "react-modal-video"

const Hero = ({ data, home, className, about }) => {
  const heroImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [video, setVideo] = useState(false)

  const toggleVideo = (e) => {
    e && e.preventDefault()
    setVideo(!video)
  }
  return (
    <section
      className={`hero with-pattern py-5 ${
        home ? "hero-home  d-flex align-items-center" : ""
      } ${className ? className : ""}`}
    >
      {home ? (
        <Container className="py-5 mt-5">
          <Row className="align-items-center">
            <Col md="6" className="mb-5 mb-md-0">
              <h1
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: data.headline }}
              />
              <p className="text-muted">{data.content}</p>
              {data.video && (
                <Media
                  tag="a"
                  className="video-btn media align-items-center"
                  href="#"
                  onClick={(e) => toggleVideo(e)}
                >
                  <span className="video-btn-icon">
                    <FontAwesomeIcon icon={faPlay} />
                  </span>
                  <Media body className="ml-3">
                    <span className="text-muted d-block line-height-sm">
                      {data.video.title}
                    </span>
                    <strong className="text-uppercase text-dark">
                      {data.video.subtitle}
                    </strong>
                  </Media>
                </Media>
              )}
            </Col>
            <Col md="5" className="ml-auto">
              <div className="img-gradient img-gradient-right">
                <Img
                  fluid={heroImage.file.childImageSharp.fluid}
                  alt=""
                  className="img-fluid shadow rounded"
                />
              </div>
            </Col>
          </Row>
          {data.video && (
            <ModalVideo
              channel={data.video.type}
              isOpen={video}
              videoId={data.video.id}
              onClose={() => toggleVideo()}
            />
          )}
        </Container>
      ) : about ? (
        <Container className="py-5 my-5">
          {data.mainColumn && (
            <Row className="mb-4">
              <Col md="7">
                <p className="h6 text-uppercase text-primary">
                  {data.mainColumn.subtitle}
                </p>
                <h1>{data.mainColumn.title}</h1>
                <p className="text-muted">{data.mainColumn.content}</p>
              </Col>
            </Row>
          )}
          <Row>
            {data.columns.map((column) => (
              <Col
                key={column}
                md="6"
                dangerouslySetInnerHTML={{ __html: column }}
              />
            ))}
          </Row>
        </Container>
      ) : (
        <Container className="py-5 my-5">
          <Row>
            <Col md="7" className="mx-auto text-center">
              <p className="h6 text-uppercase text-primary">{data.subtitle}</p>
              <h1>{data.title}</h1>
              <p className="text-muted">{data.content}</p>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  )
}

export default Hero
